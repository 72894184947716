import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './get-equipment.reducers';
import { GetEquipmentEffects } from './get-equipment.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ GetEquipmentEffects ]),
    StoreModule.forFeature('getEquipment', reducer),
  ],
})
export class GetEquipmentModule {
}
