import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getEquipmentAction, getEquipmentFailureAction, getEquipmentSuccessAction } from './get-equipment.actions';
import { GetEquipmentService } from './get-equipment.service';

@Injectable()
export class GetEquipmentEffects {
  getEquipment$ = createEffect(() => this.actions$.pipe(
    ofType(getEquipmentAction),
    switchMap(action =>
      this.getEquipmentService.get(action.payload.id).pipe(
        map(data => getEquipmentSuccessAction({ data })),
        catchError(error => of(getEquipmentFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getEquipmentService: GetEquipmentService,
  ) {
  }
}
