import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { Equipment } from "../../../models/equipment";

export const getEquipmentAction = createAction(
  ActionTypes.GET_EQUIPMENT,
  props<{ payload: { id: string } }>(),
);

export const getEquipmentSuccessAction = createAction(
  ActionTypes.GET_EQUIPMENT_SUCCESS,
  props<{ data: Equipment }>()
);

export const getEquipmentFailureAction = createAction(
  ActionTypes.GET_EQUIPMENT_FAILURE,
  props<{ error: unknown }>()
);
