import { Action, createReducer, on } from '@ngrx/store';
import {
  getEquipmentAction, getEquipmentSuccessAction, getEquipmentFailureAction,
} from './get-equipment.actions';
import { GetEquipmentState } from './get-equipment.types';

const initialState: GetEquipmentState = {
  updating: false,
  updated: false,
};

const getEquipmentReducer = createReducer<GetEquipmentState>(
  initialState,
  on(
    getEquipmentAction,
    state => ({
      ...state,
      updating: true,
      updated: false,
      data: undefined,
    })
  ),
  on(
    getEquipmentSuccessAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: true,
      data: action.data,
      error: undefined,
    })
  ),
  on(
    getEquipmentFailureAction,
    (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: GetEquipmentState, action: Action) => getEquipmentReducer(state, action);
