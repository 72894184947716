import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { decodeZodData } from "../../../models/handle-schema";
import { Equipment, equipmentWithIdSchema } from "../../../models/equipment";

@Injectable({
  providedIn: 'root',
})
export class GetEquipmentService {
  constructor(private http: HttpClient) {
  }

  get(id: string): Observable<Equipment> {
    return this.http.get<unknown>(`${ environment.apiV2.equipmentURL }/${ id }`).pipe(
      decodeZodData(equipmentWithIdSchema),
    );
  }
}
